import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { RichText } from "prismic-reactjs";
import { Grid, Row, Col } from "react-styled-flexboxgrid";

import Heading from "../typography/Heading";
import ButtonLink from "../ButtonLink";
import triangle from "../../../images/triangle-upright-blue.svg";

const Container = styled.section`
  position: relative;
  background-color: ${props =>
    props.variant === "blue" ? props.theme.colors.blue : "transparent"};
  color: ${props =>
    props.variant === "blue"
      ? props.theme.colors.white
      : props.theme.colors.primary};
  background-blend-mode: multiply;
  overflow: hidden;
  z-index: ${props => props.theme.zIndex.section};

  p {
    margin-bottom: 16px;
    font-size: 18px;

    @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
      font-size: 22px;
      line-height: 1.4;
    }

    @media (min-width: ${props => props.theme.breakpoints.desktop}px) {
      font-size: 24px;
    }
  }
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  padding: ${(props) => (props.tight ? "80px 0" : "100px 0")};
  z-index: ${(props) => props.theme.zIndex.content};

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    padding: ${(props) => (props.tight ? "160px 0" : "200px 0")};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    padding: ${(props) => (props.tight ? "176px 0" : "246px 0")};
  }
`;

const Shape = styled.div`
  display: none;
  z-index: ${props => props.theme.zIndex.shape};

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: url(${triangle});
    background-repeat: no-repeat;
    background-size: cover;
    width: 800px;
    height: 160%;
  }

  @media (min-width: ${props => props.theme.breakpoints.desktop}px) {
    height: 201%;
    width: 900px;
  }

  @media (min-width: 1800px) {
    height: 170%;
  }
`;

const CopyBlock = ({
  heading,
  body,
  list,
  cta,
  target,
  variant = "blue",
  wide = false,
  tight = false,
}) => (
  <Container variant={variant}>
    <Content tight={tight}>
      <Grid>
        <Row>
          <Col xs={12} md={wide ? 9 : 6} mdOffset={1}>
            {heading && (
              <Heading
                size="l"
                as="h2"
                margin="0 0 xxl"
                light={variant === "blue"}
              >
                {heading}
              </Heading>
            )}
          </Col>
          <Col xs={12} md={9} mdOffset={1}>
            {body && RichText.render(body)}
            {cta && target && (
              <ButtonLink
                title="cta"
                to={target}
                variant={variant === "blue" ? "secondary" : "primary"}
              />
            )}{" "}
          </Col>
        </Row>
      </Grid>
    </Content>
    <Shape />
  </Container>
);

CopyBlock.propTypes = {
  body: PropTypes.array,
  list: PropTypes.array,
  heading: PropTypes.string,
  cta: PropTypes.string,
  target: PropTypes.string,
  variant: PropTypes.oneOf(["blue", "white"]),
};

export default CopyBlock;
