import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Grid, Row, Col } from "react-styled-flexboxgrid";

import ButtonLink from "../ButtonLink";
import Quote from "../typography/Quote";
import Heading from "../typography/Heading";
import Text from "../typography/Text";

const Container = styled.section`
  position: relative;
  padding-top: 40px;
  background-color: ${(props) => props.theme.colors.white};
  z-index: ${(props) => props.theme.zIndex.section};
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: ${(props) =>
      props.showShape ? "transparent" : props.theme.colors.white};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    padding-top: 150px;
    padding-bottom: 150px;
  }
`;

const QuoteContainer = styled.div`
  margin: 30px 35px;
  display: flex;
  flex-direction: column;

  @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
    margin: 30px 40px 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    margin: 30px 24px 0 24px;
  }
`;

const Author = styled.p`
  font-size: 18px;
  color: ${(props) => props.theme.colors.grey03};
`;

const Image = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  padding-bottom: 100%;
  border-radius: 50%;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 0;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    margin-top: 20px;
  }
`;

const Shape = styled.div`
  display: none;
  background: ${(props) => props.theme.colors.white};

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    display: block;
    background: ${(props) => props.theme.colors.grey03};
    clip-path: polygon(18% 0%, 0% 47%, 0% 100%, 0% 0%);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: -1px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    clip-path: polygon(13% 0%, 0% 36%, 0% 100%, 0% 0%);
  }
`;

const QuoteBlock = ({ quote, image, author, cta, showShape = false }) => (
  <Container showShape={showShape}>
    <Grid>
      <Row>
        <Col xs={12} md={10} mdOffset={1} lg={8} lgOffset={2}>
          <Heading size="l" as="h2" margin="0 0 xl">
            Call to real action.
          </Heading>
          <Text size="l" margin="0 0 xxl">
            We’re here, we’re excited and we’re ready to put our experience and
            diversity to work for you. Great results await.
          </Text>
          {cta && (
            <ButtonLink
              title={cta}
              to="/contact"
              variant="primary"
              margin="0 0 xxl"
            />
          )}
        </Col>
      </Row>
      {quote && (
        <Row>
          <Col xs={12} md={3} mdOffset={1} lg={2} lgOffset={2}>
            <Image image={image.url} />
          </Col>
          <Col xs={12} md={7} lg={6}>
            <QuoteContainer>
              <Quote>{quote}</Quote>
              {author && <Author>{author}</Author>}
            </QuoteContainer>
          </Col>
        </Row>
      )}
    </Grid>
  </Container>
);

QuoteBlock.propTypes = {
  quote: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  author: PropTypes.string,
  cta: PropTypes.string,
  showShape: PropTypes.bool,
};

export default QuoteBlock;
