import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  position: relative;

  @media (min-width: ${props => props.theme.breakpoints.desktop}px) {
    width: 80%;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const LargeContainer = styled.ul`
  display: inline-block;
  padding-left: 0;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    column-count: 2;
  }
`;

const ListItem = styled.li`
  margin: 5px 0;
  padding-bottom: ${(props) => (props.largeList ? "16px" : "0")};
  font-size: 16px;
  display: flex;
  padding-right: 10px;
  line-height: 1;
  align-items: ${(props) => (props.largeList ? "flex-start" : "center")};

  @media (min-width: ${props => props.theme.breakpoints.mobile}px) {
    font-size: 18px;
    padding-right: 20px;
  }

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    font-size: 22px;
    white-space: ${(props) => (props.largeList ? "normal" : "nowrap")};
    padding-right: 40px;
    padding-bottom: 16px;

    ${(props) => props.largeList &&
      ` break-inside: avoid;
        column-fill: balance;
        column-span: none;`}
  }

  @media (min-width: ${props => props.theme.breakpoints.desktop}px) {
    font-size: 24px;
  }

  &::before {
    content: "•";
    color: ${props => props.theme.colors.grey03};
    font-size: 24px;
    line-height: 1;
    display: inline-block;
    width: 12px;
    margin-right: 8px;
    margin-top: -2px;
  }
`;

const List = ({ items, largeList = false }) => {
  if (largeList) {
    return (
      <LargeContainer>
        {items.map((item, i) => (
          <ListItem key={`${item}_${i}`} largeList>
            {item.text}
          </ListItem>
        ))}
      </LargeContainer>
    );
  } else {
    return (
      <Container>
        {items.map((item, i) => (
          <ListItem key={`${item}_${i}`}>{item.text}</ListItem>
        ))}
      </Container>
    );
  }
};

List.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isrequired,
    })
  ).isRequired,
  largerList: PropTypes.bool,
};

export default List;
