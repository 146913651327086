import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Grid, Row, Col } from "react-styled-flexboxgrid";

import QuoteBlock from "./QuoteBlock";
import PortraitCard from "../PortraitCard";
import Heading from "../typography/Heading";
import Spacer from "../Spacer";

import triangle from "../../../images/triangle-leadership.svg";

const Container = styled.section`
  position: relative;
  overflow: hidden;
  background-color: ${props => props.theme.colors.white};
  z-index: ${props => props.theme.zIndex.section};
`;

const Content = styled.div`
  position: relative;
  z-index: ${props => props.theme.zIndex.content};

  background-color: ${props => props.theme.colors.grey04};
  padding-top: 60px;
  padding-bottom: 0;

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    background-color: transparent;
    padding-top: 90px;
  }

  @media (min-width: ${props => props.theme.breakpoints.desktop}px) {
    padding-top: 120px;
  }
`;

const Shape = styled.div`
  z-index: ${props => props.theme.zIndex.shape};

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${triangle});
    background-repeat: no-repeat;
    background-size: 90%;
  }
`;

const LeadershipBlock = ({ heading, leaders, quote }) => (
  <Container>
    <Content>
      <Grid>
        {heading && (
          <Row>
            <Col xs={12} md={8}>
              <Spacer
                padding={{ xs: "0 0 20px", m: "0 0 50px", l: "0 0 70px" }}
              >
                <Heading as="h2" size="l">
                  {heading}
                </Heading>
              </Spacer>
            </Col>
          </Row>
        )}

        {leaders && (
          <Row>
            {leaders.map((leader, i) => (
              <Col xs={12} sm={6} md={4} key={`leader-${i}`}>
                <PortraitCard
                  id={leader.node._meta.uid}
                  name={leader.node.leader_name}
                  title={leader.node.leader_job_title}
                  biography={leader.node.leader_biography}
                  image={leader.node.leader_picture}
                />
              </Col>
            ))}
          </Row>
        )}
      </Grid>
      {quote && (
        <QuoteBlock
          quote={quote.quote}
          author={quote.author}
          cta={quote.cta}
          image={quote.image}
          showShape
        />
      )}
    </Content>
    <Shape />
  </Container>
);

LeadershipBlock.propTypes = {
  heading: PropTypes.string,
  leader: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        _meta: PropTypes.shape({
          uid: PropTypes.string.isRequired,
        }).isRequired,
        name: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        biography: PropTypes.string.isRequired,
        image: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }),
      }).isRequired,
    })
  ),
  quote: PropTypes.shape({
    quote: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    author: PropTypes.string,
    cta: PropTypes.string,
  }),
};

export default LeadershipBlock;
