import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "gatsby-plugin-modal-routing";

import Spacer from "./Spacer";
import UnstyledButton from "./UnstyledButton";
import Text from "./typography/Text";

const CardLink = styled(Link)`
  display: block;
  text-decoration: none;
  transition: all 100ms ease-in-out;
  background-color: ${props => props.theme.colors.white};

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    background-color: transparent;
  }

  &:hover {
    background-color: ${props => props.theme.colors.white};
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.225);
  }
`;

const Container = styled.div`
  margin-bottom: 48px;
  position: relative;
  cursor: pointer;

  @media (min-width: ${props => props.theme.breakpoints.desktop}px) {
    margin-bottom: 80px;
  }

  &:hover {
    div:first-child {
      filter: none;
    }

    button {
      color: ${props => props.theme.colors.blue};
      transition: background 100ms ease-in-out;
      border-bottom: 2px solid ${props => props.theme.colors.blue};
    }
  }
`;

const Portrait = styled.div`
  background-color: ${props => props.theme.colors.grey01};
  background-image: ${props => props.image && `url(${props.image})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  padding-top: 100%;
  margin-bottom: ${props => props.theme.spacing.m};
  filter: gray;
  filter: grayscale(1);
  transition: all 100ms ease-in-out;
`;

const Name = styled.h4`
  color: ${props => props.theme.colors.primary};
`;

const PortraitCard = ({ name, title, image, id }) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <CardLink to={`/about/${id}`} asModal state={{ noScroll: true }}>
      <Container>
        <Portrait image={image.url} />
        <Spacer padding={{ xs: "0 8px 8px" }}>
          <Name>{name}</Name>
          <Text showSlash>{title}</Text>
          <UnstyledButton margin="xl 0 0">View bio</UnstyledButton>
        </Spacer>
      </Container>
    </CardLink>
  );
};

PortraitCard.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default PortraitCard;
