import PropTypes from "prop-types";
import styled from "styled-components";

import quoteMarks from "../../../images/icons/quote-marks.svg";

const Quote = styled.q`
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: ${props =>
    props.colour === "default"
      ? props.theme.colors.primary
      : props.theme.colors.grey03};
  padding-bottom: 50px;
  text-align: left;
  ${props => props.italic && `font-style: italic;`};

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    font-size: 20px;
    padding-bottom: 50px;
  }

  @media (min-width: ${props => props.theme.breakpoints.desktop}px) {
    font-size: 24px;
  }

  &::before {
    content: url(${quoteMarks});
    position: absolute;
    top: -30px;
    left: -34px;
    ${props => props.lighter && `filter: opacity(0.5);`}
  }

  &::after {
    content: url(${quoteMarks});
    position: absolute;
    margin: 30px 0 0 5px;
    ${props => props.lighter && `filter: opacity(0.5);`}
  }
`;

Quote.propTypes = {
  colour: PropTypes.oneOf(["default", "grey"]),
  lighter: PropTypes.bool,
  italic: PropTypes.bool,
};

Quote.defaultProps = {
  colour: "default",
  lighter: false,
  italic: false,
};

export default Quote;
