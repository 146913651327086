import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { RichText } from "prismic-reactjs";
import { Grid, Row, Col } from "react-styled-flexboxgrid";

import Heading from "../typography/Heading";
import Quote from "../typography/Quote";
import Spacer from "../Spacer";
import List from "../List";

import triangle from "../../../images/triangle-upright-grey.svg";

const Container = styled.section`
  background: ${props => props.theme.colors.white};
  position: relative;
  overflow: hidden;
  z-index: ${props => props.theme.zIndex.section};
`;

const Content = styled.div`
  position: relative;
  background-color: ${props =>
    props.variant === "blue" ? props.theme.colors.blue : "white"};
  background-blend-mode: multiply;
  padding-top: 100px;
  padding-bottom: 80px;
  z-index: ${props => props.theme.zIndex.content};

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    padding-top: 120px;
    padding-bottom: 110px;
    background-color: transparent;
  }

  @media (min-width: ${props => props.theme.breakpoints.desktop}px) {
    padding-top: 160px;
    padding-bottom: 140px;
  }

  color: ${props =>
    props.variant === "blue"
      ? props.theme.colors.white
      : props.theme.colors.primary};

  p {
    margin-bottom: 16px;
    font-size: 18px;

    @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
      font-size: 22px;
      line-height: 1.4;
    }

    @media (min-width: ${props => props.theme.breakpoints.desktop}px) {
      font-size: 24px;
    }
  }
`;

const Shape = styled.div`
  z-index: ${props => props.theme.zIndex.shape};

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 690px;
    height: 433px;
    background-image: url(${triangle});
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.desktop}px) {
    width: 600px;
    height: 49%;
    left: -45px;
  }

  @media (min-width: 1800px) {
    height: 509px;
  }
`;

const SpecialisationBlock = ({
  heading,
  body,
  subheading,
  secondaryBody,
  subheading2,
  thirdBody,
  specialties,
  quote,
}) => (
  <Container>
    <Content>
      <Grid>
        <Row>
          <Col xs={12} md={10} mdOffset={1}>
            {heading && (
              <Heading size="l" as="h2" margin="0 0 xxl">
                {heading}
              </Heading>
            )}
            {RichText.render(body)}
            <Spacer
              padding={{
                xs: "xl 0 xl",
              }}
              margin={{
                xs: "xl 0 s",
              }}
            >
              {subheading && (
                <Heading size="m" as="h2" margin="0 0 xxl">
                  {subheading}
                </Heading>
              )}
              {RichText.render(secondaryBody)}
            </Spacer>
            <Spacer>
              <List items={specialties} />
            </Spacer>
            <Spacer
              padding={{
                xs: "xxl 0 xxxl",
              }}
              margin={{
                xs: "l 0 xxl",
              }}
            >
              {subheading2 && (
                <Heading size="m" as="h3" margin="0 0 xxl">
                  {subheading2}
                </Heading>
              )}
              {RichText.render(thirdBody)}
            </Spacer>
          </Col>
          <Col xs={9} xsOffset={2} md={8} mdOffset={4} lg={6} lgOffset={6}>
            <Quote colour="grey" lighter>
              {quote}
            </Quote>
          </Col>
        </Row>
      </Grid>
    </Content>
    <Shape />
  </Container>
);

SpecialisationBlock.propTypes = {};

export default SpecialisationBlock;
