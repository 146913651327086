import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withMargin } from "../../theme/spacing";

const UnstyledButton = styled.button`
  background-color: transparent;
  color: ${props => props.theme.colors.grey01};
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  max-width: fit-content;
  padding: 0;
  padding-bottom: 4px;
  outline: none;
  border: 0;
  border-bottom: 2px solid ${props => props.theme.colors.grey01};
  border-radius: 0;
  position: relative;
  white-space: nowrap;
  transition: all 100ms ease-in-out;
  line-height: 1;
  ${props => withMargin(props)}

  @media (min-width: ${props => props.theme.breakpoints.desktop}px) {
    font-size: 18px;
  }

  &:hover {
    color: ${props => props.theme.colors.blue};
    cursor: pointer;
    border-bottom: 2px solid ${props => props.theme.colors.blue};
  }
`;

export default UnstyledButton;
