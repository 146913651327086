import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { RichText } from "prismic-reactjs";
import { Grid, Row, Col } from "react-styled-flexboxgrid";

import triangle from "../../../images/triangle-upright-white.svg";
import Heading from "../typography/Heading";
import ButtonLink from "../ButtonLink";

const Container = styled.section`
  position: relative;
  ${props =>
    props.background &&
    css`
      background-image: url(${props.background});
      background-position: right;
      background-repeat: no-repeat;
      background-size: cover;
    `}
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.primary};
  overflow: hidden;
  z-index: ${props => props.theme.zIndex.section};

  p {
    margin-bottom: 16px;
    font-size: 18px;

    @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
      font-size: 22px;
      line-height: 1.4;
    }

    @media (min-width: ${props => props.theme.breakpoints.desktop}px) {
      font-size: 24px;
    }
  }
`;

const Content = styled.div`
  position: relative;
  display: block;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  padding: 100px 0;
  z-index: ${props => props.theme.zIndex.content};

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    padding: 150px 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.desktop}px) {
    padding: 250px 0;
  }
`;

const Shape = styled.div`
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: url(${triangle});
  background-repeat: no-repeat;
  background-size: cover;
  width: 700px;
  height: 165%;
  z-index: ${props => props.theme.zIndex.shape};

  @media (min-width: ${props => props.theme.breakpoints.mobile}px) {
    width: 1190px;
    height: 220%;
  }

  @media (min-width: ${props => props.theme.breakpoints.tablet}px) {
    width: 1200px;
    height: 280%;
  }

  @media (min-width: ${props => props.theme.breakpoints.desktop}px) {
    width: 1400px;
    height: 269%;
  }

  @media (min-width: 1800px) {
    width: 100%;
    height: 350%;
  }
`;

const ShapesBlock = ({ heading, background, body, cta, target }) => (
  <Container background={background.url}>
    <Content>
      <Grid>
        <Row>
          <Col xs={10} md={8} mdOffset={1} lg={6} lgOffset={1}>
            {heading && (
              <Heading size="l" margin="0 0 xxl" as="h2">
                {heading}
              </Heading>
            )}
            {RichText.render(body)}
            {cta && target && (
              <ButtonLink
                title={cta}
                to={target}
                margin="xxl 0 0"
                variant="secondary"
              />
            )}
          </Col>
        </Row>
      </Grid>
    </Content>
    <Shape />
  </Container>
);

ShapesBlock.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.array.isRequired,
  background: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  cta: PropTypes.string,
  target: PropTypes.string,
};

export default ShapesBlock;
