import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { useRecoilValue } from "recoil";

import { browserState } from "../components/new/ScrollAware";
import Layout from "../components/new/Layout";
import FixedBlock from "../components/new/blocks/FixedBlock";
import HeroBlock from "../components/new/blocks/HeroBlock";
import CopyBlock from "../components/new/blocks/CopyBlock";
import SpecialisationBlock from "../components/new/blocks/SpecialisationBlock";
import ShapesBlock from "../components/new/blocks/ShapesBlock";
import ArrowImageBlock from "../components/new/blocks/ArrowImageBlock";
import LeadershipBlock from "../components/new/blocks/LeadershipBlock";

import { extractSocialMetadata } from "../utils/content";
import theme from "../theme/";

const AboutPage = ({ data }) => {
  const { isIE } = useRecoilValue(browserState);
  const content = data.prismic.about;
  const capabilities = data.prismic.allCapabilitiess.edges;
  const leaders = data.prismic.allLeaders.edges;
  const quotationFeature = data.prismic.allQuotation_feature_blocks.edges;
  const meta = data.site.siteMetadata;

  if (!content) {
    return null;
  }

  const [hasSocialMetaData, socialMetadata] = extractSocialMetadata(
    content.body1
  );

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        title={content.meta_title}
        meta={[
          {
            name: `description`,
            content: content.meta_description,
          },
          {
            property: `og:title`,
            content: hasSocialMetaData
              ? socialMetadata.general_card.title
              : meta.title,
          },
          {
            property: `og:description`,
            content: hasSocialMetaData
              ? socialMetadata.general_card.description
              : content.meta_description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: hasSocialMetaData && socialMetadata.general_card.image,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: meta.author,
          },
          {
            name: `twitter:site`,
            content: hasSocialMetaData && socialMetadata.twitter_handle,
          },
          {
            name: `twitter:title`,
            content: hasSocialMetaData
              ? socialMetadata.twitter_card.title
              : meta.title,
          },
          {
            name: `twitter:description`,
            content: hasSocialMetaData
              ? socialMetadata.twitter_card.description
              : meta.description,
          },
          {
            name: `twitter:image`,
            content: hasSocialMetaData && socialMetadata.twitter_card.image,
          },
        ].concat(meta)}
      />
      <Layout>
        {content.body.map((section, i) => {
          const key = `${section.type}_${i}`;
          const { type, primary: data } = section;

          switch (type) {
            case "videoplayer":
              return (
                <FixedBlock
                  index={isIE ? theme.zIndex.background : theme.zIndex.swipe}
                  key={key}
                >
                  <HeroBlock
                    image={data.video_player_video_fallback_image}
                    preview={data.video_player_video_source_preview}
                    video={data.video_player_video_source_full}
                    title={data.video_player_heading[0].text}
                  />
                </FixedBlock>
              );
            case "simple_copy_block":
              return (
                <CopyBlock
                  key={key}
                  heading={data.simple_copy_block_heading}
                  body={data.simple_copy_block_body_copy}
                  cta={data.simple_copy_block_cta_copy}
                  target={data.simple_copy_block_cta_target}
                  variant={data.simple_copy_block_variant}
                />
              );
            case "specialisation_overview_block":
              return (
                <SpecialisationBlock
                  key={key}
                  heading={data.specialisation_block_heading}
                  body={data.specialisation_block_body_copy}
                  subheading={data.specialisation_block_subheading}
                  secondaryBody={data.specialisation_block_secondary_body_copy}
                  subheading2={data.specialisation_block_subheading2}
                  thirdBody={data.specialisation_block_third_body_copy}
                  specialties={data.specialisation_block_specialties}
                  quote={data.specialisation_block_quote}
                />
              );
            case "slanted_image_copy_block":
              return (
                <ShapesBlock
                  key={key}
                  heading={data.slanted_image_block_heading}
                  background={data.slanted_image_background_image}
                  body={data.slanted_image_block_body_copy}
                  cta={data.slanted_image_block_cta_copy}
                  target={data.slanted_image_block_cta_target}
                />
              );
            case "3_image_block":
              const {
                multiple_arrow_image_block_headline,
                multiple_arrow_image_block_body_copy,
                multiple_arrow_image_block_image_1,
                multiple_arrow_image_block_image_2,
              } = data.about_multiple_arrow_image_block;

              return (
                <ArrowImageBlock
                  key={key}
                  heading={multiple_arrow_image_block_headline}
                  body={multiple_arrow_image_block_body_copy}
                  foreground={multiple_arrow_image_block_image_1}
                  background={multiple_arrow_image_block_image_2}
                />
              );
            case "leadership_block":
              const {
                quotation_block_quote,
                quotation_block_source,
                quotation_block_cta_copy,
                quotation_block_image,
              } = quotationFeature[0].node;

              return (
                <LeadershipBlock
                  key={key}
                  heading={data.block_title}
                  leaders={leaders}
                  quote={{
                    quote: quotation_block_quote,
                    author: quotation_block_source,
                    cta: quotation_block_cta_copy,
                    image: quotation_block_image,
                  }}
                />
              );
          }
        })}
      </Layout>
    </>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

const query = graphql`
  {
    prismic {
      about(uid: "about", lang: "en-gb") {
        meta_description
        meta_title
        body {
          ... on PRISMIC_AboutBodyVideoplayer {
            type
            label
            primary {
              video_player_heading
              video_player_video_fallback_image {
                ... on PRISMIC__ImageLink {
                  _linkType
                  url
                }
              }
              video_player_video_source_preview {
                ... on PRISMIC__FileLink {
                  _linkType
                  url
                }
              }
              video_player_video_source_full {
                ... on PRISMIC__FileLink {
                  _linkType
                  url
                }
              }
            }
          }
          ... on PRISMIC_AboutBodySpecialisation_overview_block {
            type
            label
            primary {
              specialisation_block_body_copy
              specialisation_block_heading
              specialisation_block_subheading
              specialisation_block_secondary_body_copy
              specialisation_block_specialties
              specialisation_block_subheading2
              specialisation_block_third_body_copy
              specialisation_block_quote
            }
          }
          ... on PRISMIC_AboutBodySimple_copy_block {
            type
            label
            primary {
              simple_copy_block_body_copy
              simple_copy_block_cta_copy
              simple_copy_block_cta_target
              simple_copy_block_heading
              simple_copy_block_variant
            }
          }
          ... on PRISMIC_AboutBodySlanted_image_copy_block {
            type
            label
            primary {
              slanted_image_block_heading
              slanted_image_block_body_copy
              slanted_image_block_cta_copy
              slanted_image_block_cta_target
              slanted_image_background_image
            }
          }
          ... on PRISMIC_AboutBody3_image_block {
            type
            label
            primary {
              about_multiple_arrow_image_block {
                ... on PRISMIC_Expertise_block {
                  multiple_arrow_image_block_headline
                  multiple_arrow_image_block_body_copy
                  multiple_arrow_image_block_cta_copy
                  multiple_arrow_image_block_cta_target
                  multiple_arrow_image_block_image_1
                  multiple_arrow_image_block_image_2
                  multiple_arrow_image_block_image_3
                }
              }
            }
          }
          ... on PRISMIC_AboutBodyLeadership_block {
            type
            label
            primary {
              block_title
            }
          }
        }
        body1 {
          ... on PRISMIC_AboutBody1General_card {
            type
            label
            primary {
              description
              image
              title
            }
          }
          ... on PRISMIC_AboutBody1Twitter_card {
            type
            label
            primary {
              card_type
              image
              description
              title
              twitter_handle
            }
          }
        }
      }
      allQuotation_feature_blocks {
        edges {
          node {
            quotation_block_quote
            quotation_block_source
            quotation_block_cta_copy
            quotation_block_image {
              ... on PRISMIC__ImageLink {
                _linkType
                url
              }
            }
          }
        }
      }
      allCapabilitiess {
        edges {
          node {
            capability_label
          }
        }
      }
      allLeaders {
        edges {
          node {
            _meta {
              uid
            }
            leader_name
            leader_job_title
            leader_biography
            leader_picture
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;

const AboutData = () => (
  <StaticQuery query={`${query}`} render={data => <AboutPage data={data} />} />
);

export default AboutData;
