import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { RichText } from "prismic-reactjs";
import { Grid, Row, Col } from "react-styled-flexboxgrid";

import Heading from "../typography/Heading";
import redArrowShape from "../../../images/arrow-shape-red.svg";

const Container = styled.section`
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: ${(props) => props.theme.zIndex.section};

  background-color: #eb5757;
  background-position-y: center;
  background-image: url(${redArrowShape});
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
    padding-top: 200%;

    ${(props) =>
      props.background &&
      css`
        background-image: url(${props.background});
        background-repeat: no-repeat;
        background-size: cover;
      `}
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    padding-top: 105%;
  }

  @media (min-width: 992px) {
    padding-top: 80%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    padding-top: 62.5%;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  position: relative;
  z-index: ${(props) => props.theme.zIndex.content};
  color: ${(props) => props.theme.colors.white};
  margin: 20% 0;

  @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  p {
    margin-bottom: 16px;
    font-size: 18px;

    @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
      font-size: 22px;
      line-height: 1.4;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
      font-size: 24px;
    }
  }
`;

const Shape = styled.div`
  z-index: ${(props) => props.theme.zIndex.shape};
  display: none;
  position: absolute;
  height: 100%;
  width: 170%;
  top: 0;
  left: 0;
  background-position-y: center;
  background-image: url(${redArrowShape});
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
    display: block;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    background-image: url(${redArrowShape});
    background-repeat: no-repeat;
    background-size: cover;
    width: 120%;
    left: -51%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    left: -51%;
  }
`;

const ImageShape = styled.div`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
    display: block;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    display: block;
    ${(props) =>
      props.image &&
      css`
        background-image: url(${props.image});
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: center;
        background-position-x: 100%;
      `}
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: -3%;
    z-index: ${(props) => props.theme.zIndex.shape};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    left: -10%;
    background-position-x: 100%;
  }
`;

const ArrowImageBlock = ({ heading, body, background, foreground }) => (
  <Container>
    <Content>
      <Grid>
        <Row>
          <Col xs={12} sm={11} md={6}>
            {heading && (
              <Heading size="xl" as="h2" margin="0 0 xxl" light>
                {heading}
              </Heading>
            )}
            {RichText.render(body)}
          </Col>
        </Row>
      </Grid>
    </Content>
  </Container>
);

ArrowImageBlock.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.array.isRequired,
  background: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  foreground: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default ArrowImageBlock;
